<template>
	<div class="popup_sub_container">
		<h2>VIEW / EDIT HUB/MAILROOM LOCATION</h2>
		<i class="closeme" @click="closeModal">X</i>
		<section v-if="location_id">
			<div class="jobs_table sp">
				<div class>
					<ValidationObserver ref="observer" v-slot="{ invalid }" slim>
						<section>
							<div class="form_section1">
								<table>
									<tr>
										<td>
											<ValidationProvider name="name" rules="required|max:30" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Hub/Mailroom Location Name
														<span>*</span>
													</label>
													<input type="text"
														placeholder="Mailroom Location Name Character Limit 30"
														:class="errors[0] ? 'warning-border' : ''" v-model="division.name"
														:maxlength="max" :disabled="!is_editable" />
												</div>
											</ValidationProvider>
										</td>
										<td>
											<ValidationProvider name="code" rules="required|max:30" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Hub/Mailroom Location Code
														<span>*</span>
													</label>
													<input type="text"
														placeholder="Mailroom Location Code Character Limit 30"
														:class="errors[0] ? 'warning-border' : ''"
														v-model="division.division_code" :maxlength="max"
														:disabled="!is_editable" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<td>
											<ValidationProvider name="address 1" rules="required|max:30" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Address line 1
														<span>*</span>
													</label>
													<input type="text" placeholder="Address line 1 Character Limit 60"
														:class="errors[0] ? 'warning-border' : ''"
														v-model="division.address_1" :maxlength="max1" />
												</div>
											</ValidationProvider>
										</td>
										<td colspan="2">
											<ValidationProvider name="address 2" rules="required|max:30" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Address line 2
														<span>*</span>
													</label>
													<input type="text" placeholder="Address line 2 Character Limit 60"
														:class="errors[0] ? 'warning-border' : ''"
														v-model="division.address_2" :maxlength="max1" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<td>
											<div class="label_and_element_wrapper">
												<label>
													Postal Code
													<span></span>
												</label>
												<input type="text" placeholder="Postal Code Character Limit 10"
													v-model="division.postal_code" :maxlength="max2" />
											</div>
										</td>
										<td colspan="2">
											<ValidationProvider name="city" rules="required|max:30" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														City
														<span>*</span>
													</label>
													<input type="text" placeholder="City Character Limit 30"
														:class="errors[0] ? 'warning-border' : ''" v-model="division.city"
														:maxlength="max" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<td>
											<div class="label_and_element_wrapper">
												<label>
													Suburb
													<span></span>
												</label>
												<input type="text" placeholder="Suburb Character Limit 30"
													v-model="division.suburb" :maxlength="max" />
											</div>
										</td>
										<td colspan="2">
											<ValidationProvider name="country" rules="required|max:30" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														Country
														<span>*</span>
													</label>
													<input type="text" placeholder="Country  Character Limit 30"
														:class="errors[0] ? 'warning-border' : ''"
														v-model="division.country" :maxlength="max" />
												</div>
											</ValidationProvider>
										</td>
									</tr>
									<tr>
										<td>
											<ValidationProvider name="state" rules="required|max:30" v-slot="{ errors }"
												slim>
												<div class="label_and_element_wrapper">
													<label>
														State/Province
														<span>*</span>
													</label>
													<input type="text" placeholder="State/Province Character Limit 30"
														:class="errors[0] ? 'warning-border' : ''" v-model="division.state"
														:maxlength="max" />
												</div>
											</ValidationProvider>
										</td>
										<td colspan="2">
											<!-- <ValidationProvider name="email" rules="required|max:30" v-slot="{ errors }" slim> -->
											<div class="label_and_element_wrapper">
												<label>
													Email
													<!-- <span>*</span> -->
												</label>
												<input type="text" placeholder="Email Character Limit 60"
													v-model="division.email" :maxlength="max1" />
											</div>
											<!-- </ValidationProvider> -->
										</td>
									</tr>
									<tr>
										<td>
											<div class="label_and_element_wrapper">
												<label>
													Phone
													<span></span>
												</label>
												<input type="text" placeholder="Phone Character Limit 20"
													v-model="division.phone" :maxlength="max3" />
											</div>
										</td>
										<td>
											<!-- <ValidationProvider name="token" rules="required|max:100" v-slot="{ errors }" slim> -->
											<div class="label_and_element_wrapper">
												<label>
													Token
													<!-- <span>*</span> -->
												</label>
												<textarea placeholder="Token Character Limit 100" v-model="division.token"
													:maxlength="max4"></textarea>
											</div>
											<!-- </ValidationProvider> -->
										</td>
									</tr>
								</table>
								<table>
									<tr>
										<td>
											<ValidationProvider name="Enable Email Notification" rules="required"
												v-slot="{ errors }" slim>
												<div class="label_and_element_wrapper">
													<label>
														Enable Email Notification ?
														<span></span>
													</label>
													<input type="checkbox" v-model="division.email_notification"
														true-value="1" false-value="0"
														:class="errors[0] ? 'warning-border' : ''"
														:disabled="!is_editable" />
												</div>
											</ValidationProvider>
										</td>
										<td>
											<div class="label_and_element_wrapper">
												<label>
													Is Point to Point?
													<span></span>
												</label>
												<input type="checkbox" :value="config.is_point_to_point" v-model="config.is_point_to_point" true-value="1"
													false-value="0" />
											</div>
										</td>
										<td>
											<div class="label_and_element_wrapper">
												<label>
													Is Default Courier Messenger?
													<span></span>
												</label>
												<input type="checkbox" :value="config.is_default_courier_messenger" v-model="config.is_default_courier_messenger"
													true-value="1" false-value="0" />
											</div>
										</td>
									</tr>
								</table>
								<div class="sub_form_section" v-for="(location, i) in division.division_locations"
									:key="'location_container_' + i">
									<div class="add_remove_btns" v-if="is_editable">
										<a class="add_bt" @click="addLocationFields"></a>
										<a class="remove_bt" @click="removeLocationFields(location, i)"
											v-if="division.division_locations.length > 1"></a>
									</div>
									<table class="sub_table">
										<tr>
											<td>
												<ValidationProvider name="Inbound Location" rules="required|max:30"
													v-slot="{ errors }" slim>
													<div class="label_and_element_wrapper">
														<label>
															Inbound Location
															<span>*</span>
														</label>
														<input type="text" placeholder="Inbound Location Character Limit 30"
															v-model="location.inbound_location" :maxlength="max"
															:disabled="!is_editable"
															:class="errors[0] ? 'warning-border' : ''" />
													</div>
												</ValidationProvider>
											</td>
											<td>
												<ValidationProvider name="Inbound Location Reference"
													rules="required|max:30" v-slot="{ errors }" slim>
													<div class="label_and_element_wrapper">
														<label>
															Inbound Location Reference
															<span>*</span>
														</label>
														<input type="text"
															placeholder="Inbound Location Reference Character Limit 30"
															:class="errors[0] ? 'warning-border' : ''"
															v-model="location.inbound_location_ref" :maxlength="max"
															:disabled="!is_editable" />
													</div>
												</ValidationProvider>
											</td>
										</tr>
										<tr>
											<td>
												<ValidationProvider name="Outbound Location" rules="required|max:30"
													v-slot="{ errors }" slim>
													<div class="label_and_element_wrapper">
														<label>
															Outbound Location
															<span>*</span>
														</label>
														<input type="text"
															placeholder="Outbound Location Character Limit 30"
															:class="errors[0] ? 'warning-border' : ''"
															v-model="location.outbound_location" :maxlength="max"
															:disabled="!is_editable" />
													</div>
												</ValidationProvider>
											</td>
											<td>
												<ValidationProvider name="Outbound Location Reference"
													rules="required|max:30" v-slot="{ errors }" slim>
													<div class="label_and_element_wrapper">
														<label>
															Outbound Location Reference
															<span>*</span>
														</label>
														<input type="text"
															placeholder="Outbound Location Reference Character Limit 30"
															:class="errors[0] ? 'warning-border' : ''"
															v-model="location.outbound_location_ref" :maxlength="max"
															:disabled="!is_editable" />
													</div>
												</ValidationProvider>
											</td>
										</tr>
										<tr>
											<td>
												<ValidationProvider name="Email Template" rules="required"
													v-slot="{ errors }" slim>
													<div class="label_and_element_wrapper">
														<label>
															Email Title
															<span>*</span>
														</label>
														<select v-model="location.default_mailroom_email_template_id"
															:disabled="!is_editable"
															:class="errors[0] ? 'warning-border' : ''">
															<option v-if="division.email_notification == 0" value="">Select
															</option>
															<option v-if="division.email_notification == 1"
																v-for="(email_template, j) in email_templates"
																:key="'emailtemplates' + j" :value="email_template.id">
																{{ email_template.title }}</option>
														</select>
													</div>
												</ValidationProvider>
											</td>
											<td>
												<ValidationProvider name="Is Default" rules="required" v-slot="{ errors }"
													slim>
													<div class="label_and_element_wrapper">
														<label>
															Is Default
															<span></span>
														</label>
														<input type="checkbox" v-model="location.is_default" true-value="1"
															false-value="0" @click="setDefault(i)"
															:class="errors[0] ? 'warning-border' : ''"
															:disabled="!is_editable" />
													</div>
												</ValidationProvider>
											</td>
										</tr>
									</table>
								</div>
							</div>
						</section>
					</ValidationObserver>
					<section class="centered">
						<a class="link_bt bt_cancel" @click="closeModal">CANCEL</a>
						<a class="link_bt bt_save" @click="updateDepartment" :disabled="update_btn.disabled"
							v-if="is_editable">{{ update_btn.value }}</a>
					</section>
				</div>
			</div>
		</section>
	</div>
</template>
<script>
export default {
	name: "EditMailroomLocations",
	data() {
		return {
			max: 30,
			max1: 60,
			max2: 10,
			max3: 20,
			max4: 70,
			division: {
				name: "",
				division_code: "",
				email_notification: 0,
				default_mailroom_location_id: "",
				address_1: "",
				address_2: "",
				postal_code: "",
				country: "",
				city: "",
				suburb: "",
				state: "",
				phone: "",
				email: "",
				token: "",
				division_locations: [
					{
						inbound_location: "",
						inbound_location_ref: "",
						outbound_location: "",
						outbound_location_ref: "",
						default_mailroom_email_template_id: "",
						is_default: 0
					}
				],
				configs: [
					{
						key: 'is_point_to_point',
						value: 'N',
					},
					{
						key: 'is_default_courier_messenger',
						value: 'N',
					},
				],
			},
			email_templates: [],
			user: {},
			update_btn: {
				disabled: false,
				value: "UPDATE"
			},
			config: {
				is_point_to_point: 0,
				is_default_courier_messenger: 0,
			},
		};
	},
	created() {
		this.fetchDivisions();
		this.fetchUser();
	},
	methods: {
		closeModal() {
			this.$emit("closeModal");
		},
		async fetchDivisions() {
			try {
				const response$ = await this.axios.get("api/divisionslocation/showdivisionlocation/" + this.location_id);
				if (response$) {
					this.division.name = response$.data.divisions.name;
					this.division.division_code = response$.data.divisions.division_code;
					this.division.address_1 = response$.data.divisions.address_1;
					this.division.address_2 = response$.data.divisions.address_2;
					this.division.postal_code = response$.data.divisions.postal_code;
					this.division.city = response$.data.divisions.city;
					this.division.country = response$.data.divisions.country;
					this.division.suburb = response$.data.divisions.suburb;
					this.division.state = response$.data.divisions.state;
					this.division.phone = response$.data.divisions.phone;
					this.division.email = response$.data.divisions.email;
					this.division.token = response$.data.divisions.token;
					this.division.email_notification = response$.data.divisions.email_notification;
					this.division.default_mailroom_location_id = response$.data.divisions.default_mailroom_location_id ? response$.data.divisions.default_mailroom_location_id : "";
					if (response$.data.divisions.division_locations.length > 0) {
						this.division.division_locations = [];
					}
					response$.data.divisions.division_locations.map(data => {
						data.is_default = (data.id == response$.data.divisions.default_mailroom_location_id) ? 1 : 0;
						return data;
					}).forEach(element => {
						this.division.division_locations.push(element);
					});
					if (response$.data.divisions.configs.length > 0) {
						this.division.configs = [];
						response$.data.divisions.configs.forEach((config) => {
							if (typeof config !== 'undefined') {
								if (config.key == 'is_point_to_point') {
									this.config.is_point_to_point = config.value == 'Y' ? 1 : 0;
								}
								if (config.key == 'is_default_courier_messenger') {
									this.config.is_default_courier_messenger = config.value == 'Y' ? 1 : 0;
								}
							}
						});
					}
				}
			}
			catch (err) {
				console.log(err);
			}
		},
		addLocationFields() {
			this.division.division_locations.push({
				inbound_location: "",
				inbound_location_ref: "",
				outbound_location: "",
				outbound_location_ref: "",
				default_mailroom_email_template_id: "",
				is_default: 0
			});
		},
		removeLocationFields(location, index) {
			if (location.id) {
				if (confirm('Are you sure you want to delete this location?')) {
					this.axios.delete("/api/divisionslocation/deletelocation/" + location.id)
						.then(response => {
							this.toast.success(response.data.msg);
							this.division.division_locations.splice(index, 1);
							this.fetchDivisions();
						})
						.catch(error => {
							console.log(error);
						});
				}
			} else {
				this.division.division_locations.splice(index, 1);
			}
		},
		setDefault(i) {
			this.division.division_locations.forEach(element => {
				element.is_default = 0;
			});
			this.division.division_locations[i].is_default = 1;
		},
		fetchUser() {
			this.axios.get("api/user")
				.then(response => {
					this.user = response.data.user;
					this.fetchEmailTemplate();
				});
		},
		fetchEmailTemplate() {
			this.axios
				.get("/api/emailtemplate/")
				.then(response => {
					this.email_templates = response.data.email_template;
				})
				.catch(error => {
					console.log(error);
				});
		},
		updateDepartment() {
			this.division.configs = [
				{
					key: 'is_point_to_point',
					value: this.config.is_point_to_point == 1 ? 'Y' : 'N',
				},
				{
					key: 'is_default_courier_messenger',
					value: this.config.is_default_courier_messenger == 1 ? 'Y' : 'N',
				},
			];
			this.$refs.observer.validate();
			this.update_btn.disabled = true;
			this.update_btn.value = "UPDATING...";
			this.axios.put("api/divisionslocation/" + this.location_id, this.division).then(response => {
				this.toast.success(response.data.msg);
				this.update_btn.disabled = false;
				this.update_btn.value = "UPDATE";
				this.closeModal();
			})
				.catch(error => {
					this.update_btn.disabled = false;
					this.update_btn.value = "UPDATE";
					console.log(error);
				});
		}
	},
	props: {
		location_id: Number,
		is_editable: {
			type: Boolean,
			default: true
		}
	}
};
</script>
<style lang="scss">
.popup_sub_container {
	padding: 20px;
	width: 100%;
	margin: auto;

	background: #fff;
}

.popup_sub_container>section {
	padding: 20px 0 0 0;
}

h3.sub_head {
	font-size: 20px;
	font-weight: 600;
	padding: 0;
}

.preferred_hide {
	display: none;
}

.chk_prefered {
	width: 20px;
	height: 20px;
	display: inline-block;
	margin: 0 20px;
}

.chk_prefered:checked+hr+.preferred_hide {
	display: block;
}

.title_image {
	display: block;
	margin: auto;
}

.form_section1 {
	width: 100%;
	height: auto;
	padding: 20px;
	background: #ececec;
	margin: 20px 0;
}

i.closeme {
	width: 30px;
	height: 30px;
	background: #000;
	color: #06A5ED;
	font-size: 14px;
	text-align: center;
	line-height: 30px;
	border-radius: 100px;
	display: block;
	font-style: normal;
	font-weight: bold;
	position: absolute;
	right: 20px;
	top: 20px;
	cursor: pointer;
}
</style>
<style scoped>
.jobs_table table {
	border: 0;
}

.jobs_table table tr td {
	padding: 0;
	border: 0;
}

.default_checkbox {
	width: 22px;
	height: 22px
}
</style>